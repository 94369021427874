import React from 'react'

import { Background, Body } from 'components'

const App: React.FC = () => {
  return (
    <>
      <Background />
      <Body />
    </>
  )
}

export default App
